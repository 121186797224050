.footer-wrapper {
  width: 100vw;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ddd;
  background-color: #000;

  .footer {
    width: 100%;
    padding: 1rem 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    align-items: center;

    .by-kossin {
      margin-right: auto;

      p {
        font-family: "Orbitron";
        font-size: 0.9rem;
        text-transform: uppercase;
        font-weight: 900;
      }
    }

    .copyright {
      p {
        font-family: "Orbitron";
        text-transform: uppercase;
        color: #dedede;
        font-size: 0.9rem;
        font-weight: 600;
      }
    }

    .footer-icons {
      margin-left: auto;
      display: flex;
      align-items: center;

      a {
        display: grid;
        place-content: center;

        svg {
          box-sizing: content-box;
          width: 22px;
          height: 22px;
          padding: 0.5rem;
          cursor: pointer;
          color: #ddd;
          transition: 0.1s ease-in;

          border-radius: 12px;
          border: 2px solid #050505;
        }

        svg:last-child {
          margin-left: 1rem;
        }
      }

      .github {
        &:hover {
          svg {
            border: 2px solid rgba(201, 81, 12, 0.4);
            box-shadow: 5px 5px 10px 3px #c9510c;
            text-shadow: 5px 5px 10px 3px #c9510c;

            color: #c9510c;
          }
        }
      }

      .linkedin {
        &:hover {
          svg {
            border: 2px solid rgba(0, 160, 220, 0.4);
            box-shadow: 5px 5px 10px 3px #00a0dc;
            text-shadow: 5px 5px 10px 3px #00a0dc;

            color: #00a0dc;
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .footer-wrapper {
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .by-kossin {
        display: none;
      }

      .copyright {
        order: 2;
      }

      .footer-icons {
        padding: 0;
      }
    }

    .footer-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0.5rem 0;
    }
  }
}
