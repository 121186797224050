.about-education {
  .section-header {
    margin: 3rem 0 1rem 0;

    background: -webkit-linear-gradient(
      var(--light-special),
      var(--dark-special)
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: "Orbitron";
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 900;

    text-align: center;
  }

  .timeline-box {
    margin-top: 1.25rem;

    .timeline-header {
      font-weight: 900;
      font-size: 1.2rem;
      text-transform: uppercase;
      color: #ddd;

      text-align: center;
    }

    .timeline-text {
      margin-top: 1rem;
      color: #aaa;

      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      font-weight: 600;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .university-header {
      margin-top: 2rem;
      font-size: 1.5rem;

      background: -webkit-linear-gradient(
        var(--light-special),
        var(--dark-special)
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .university-content {
      margin-top: 1.2rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .university-text {
        margin-top: 0;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;

        background: -webkit-linear-gradient(
          var(--light-special),
          var(--dark-special)
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      svg {
        cursor: pointer;
        width: 40px;
        color: var(--normal-special);
        opacity: 0.75;

        &:hover {
          color: var(--normal-special);
          opacity: 1;
        }
      }
    }

    .flip {
      svg {
        position: absolute;
        animation-name: animateCap;
        animation-duration: 2.25s;
        animation-timing-function: linear;
        animation-delay: none;
        animation-direction: normal;
        opacity: 0.2;
        transform: scale(4);

        &:hover {
          cursor: default;
          -webkit-user-select: none; /* Safari */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */

          color: var(--normal-special);
          opacity: 0.2;
        }
      }
    }

    .noflip {
      svg {
        opacity: 0.35;

        &:hover {
          color: var(--light-special);
          opacity: 1;
        }
      }
    }
  }

  .skills {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 2rem;

    .skill-box {
      position: relative;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.4rem;

      .skill-header {
        margin-bottom: 0.25rem;
        font-weight: 900;
        font-size: 1.3rem;
        text-transform: uppercase;
        // color: #ddd;

        color: var(--normal-special);

        text-align: center;
      }

      svg {
        position: absolute;
        top: 1rem;
        color: var(--light-special);
        opacity: 0.12;
        width: 120px;
      }

      .skill-text {
        color: #aaa;

        font-weight: 600;
        font-size: 1.05rem;

        text-align: center;
      }
    }
  }
}

@keyframes animateCap {
  0% {
    transform: translateY(0);
  }
  12% {
    transform: translateY(-100px) rotate(1turn);
  }
  14% {
    transform: translateY(-120px) rotate(1turn);
  }
  17% {
    transform: translateY(-130px) rotate(1turn);
  }
  19% {
    transform: translateY(-132px) rotate(0.5turn);
  }
  25% {
    transform: translateY(-120px) rotate(0.5turn);
  }
  50% {
    transform: translateY(120px);
  }
  75% {
    transform: translateY(620px);
  }
  100% {
    transform: translateY(1620px);
  }
}

@media screen and (max-width: 960px) {
  .about-education {
    .section-header {
      font-size: 2rem;
    }
  }
}
