/* PROJECT */
.project {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 4rem 0;

  .info {
    position: relative;
    width: 40%;

    .title {
      font-size: 1.75rem;
      font-weight: 700;
    }

    .description {
      color: #dcdcdc;
      margin: 1.25rem 0;

      font-weight: 400;
      font-family: "Quicksand";
      font-size: 1rem;
      letter-spacing: 0.25px;
    }

    .technologies-header {
      text-transform: uppercase;
      letter-spacing: 2.5px;
      color: #c4c4c4;
      margin-top: 2.5rem;
      font-family: "Orbitron";
    }

    .technologies-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0.75rem 0 1.25rem 0;

      .technology {
        padding: 0.5rem 0.75rem;
        margin-top: 1rem;
        font-weight: 700;
        font-size: 0.8rem;
        border-radius: 14px;
        margin-right: 1.5rem;
        background-color: #252525;
        color: #dcdcdc;
      }
    }

    .link-buttons {
      margin-top: 2rem;
      display: flex;

      .link {
        .btn {
          cursor: pointer;
          padding: 0.5rem 0.75rem;
          border: none;
          border-radius: 8px;
          outline: none;
          background-color: #212121;
          color: #dcdcdc;
          transition: color 0.2s ease;

          font-family: "Orbitron";
          text-transform: uppercase;
          font-weight: 600;
        }

        .btn:hover {
          color: var(--light-special);
        }
      }

      .link:last-child {
        .btn {
          margin-left: 1rem;
        }
      }
    }
  }

  .visualization {
    width: 50%;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    .figure {
      position: relative;
      width: 100%;
      transition: all 0.15s ease-in;

      &:hover {
        transform: scale(1.1);
      }

      .project-image {
        cursor: pointer;
        width: 100%;
        height: auto;

        transition: all 0.15s ease;
      }
    }
  }
}

@media (max-width: 1320px) {
  .project {
    flex-direction: column;

    .info {
      width: 100%;
    }

    .visualization {
      margin-top: 2.5rem;
      width: 100%;

      .figure {
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .project {
    .info {
      .title {
        font-size: 1.5rem;
      }
    }
  }
}
