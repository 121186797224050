.icon {
  width: 35px;
  height: 30px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  transition: 0.1s ease;
  transform: scale(0.75);

  &:not(.open):hover span:nth-child(1) {
    transform: rotate(-3deg) scaleY(1.1);
  }

  &:not(.open):hover span:nth-child(2) {
    transform: rotate(3deg) scaleY(1.1);
  }

  &:not(.open):hover span:nth-child(3) {
    transform: rotate(-4deg) scaleY(1.1);
  }

  span {
    background-color: rgba(100, 225, 255, 1);
    position: absolute;
    border-radius: 2px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    width: 100%;
    height: 4px;
    transition-duration: 500ms;

    &:nth-child(1) {
      top: 0px;
      left: 0px;
    }

    &:nth-child(2) {
      top: 13px;
      left: 0px;
      opacity: 1;
    }

    &:nth-child(3) {
      bottom: 0px;
      left: 0px;
    }
  }
}

.icon.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 13px;
}
.icon.open span:nth-child(2) {
  opacity: 0;
}
.icon.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 13px;
}

.icon.open {
  &:hover {
    span {
      background-color: rgba(255, 0, 255, 1);
      box-shadow: 0px 1px 2px 0px rgba(255, 0, 255, 0.7),
        1px 2px 4px 0px rgba(255, 0, 255, 0.7),
        2px 4px 8px 0px rgba(255, 0, 255, 0.7),
        2px 4px 16px 0px rgba(255, 0, 255, 0.7);
    }
  }
}
.icon.disabled {
  &:hover {
    span {
      // background-color: rgba(100, 225, 255, 0.7);
      box-shadow: 0px 1px 2px 0px rgba(0, 255, 255, 0.7),
        1px 2px 4px 0px rgba(0, 255, 255, 0.7),
        2px 4px 8px 0px rgba(0, 255, 255, 0.7),
        2px 4px 16px 0px rgba(0, 255, 255, 0.7);
    }
  }
}
