@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap");

:root {
  --dark-bg: #000;
  --primary-dark: #101010;
  --secondary-dark: #121212;
  --light-special: #6bd0ff;
  --dark-special: #1a74e2;
  --normal-special: #3fa0ef;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jura", "Ubuntu", "Poppins", "Gluten", cursive;
}

body {
  width: 100vw;
  color: #fefefe;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: var(--dark-bg);
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: #000;
}

body::-webkit-scrollbar-thumb {
  background: var(--normal-special);
}

.application {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
