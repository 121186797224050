.contact-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 2px solid var(--dark-special);

  .info-heading {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 2rem;
    opacity: 0.2;
    font-family: "Orbitron";

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  .info-container {
    min-width: 200px;
    max-width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info-card {
      cursor: grab;
      margin: 0.75rem 0;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      display: flex;
      align-items: center;

      &:hover {
        svg {
          color: var(--light-special);
          transform: scale(1.1);
        }

        span {
          color: var(--special);
        }
      }

      svg {
        width: 48px !important;
        height: 48px !important;
      }

      .info-text {
        margin-left: 1.5rem;
        color: #bbb;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: "Orbitron";
        font-size: 1.25rem;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 1460px) {
  .contact-info {
    .info-container {
      .info-card {
        .info-text {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media (max-width: 1320px) {
  .contact-info {
    .info-container {
      .info-card {
        .info-text {
          font-size: 0.9rem;
        }
      }
    }
  }
}
