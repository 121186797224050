.nav-wrapper {
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #343434;

  .nav {
    width: 100%;
    padding: 0.75rem 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: #fefefe;
      text-decoration: none;
    }

    .logo {
      position: relative;
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 1.75rem;

      font-family: "Orbitron";
      font-weight: 500;
      text-transform: capitalize;

      background: -webkit-linear-gradient(
        var(--light-special),
        var(--normal-special)
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;

      &::before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0.25;
        z-index: 999;
        box-shadow: 0px 1px 2px 0px rgba(97, 219, 251, 0.7),
          1px 2px 4px 0px rgba(97, 219, 251, 0.7),
          2px 4px 8px 0px rgba(97, 219, 251, 0.7),
          2px 4px 16px 0px rgba(97, 219, 251, 0.7);
      }
      &::before {
        top: 0;
        left: 0;
        position: absolute;
        content: "";
        opacity: 0.25;
        z-index: 999;
        box-shadow: 0px 1px 2px 0px rgba(97, 219, 251, 0.7),
          1px 2px 4px 0px rgba(97, 219, 251, 0.7),
          2px 4px 8px 0px rgba(97, 219, 251, 0.7),
          2px 4px 16px 0px rgba(97, 219, 251, 0.7);
      }
    }

    .nav-menu {
      position: relative !important;
      list-style-type: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: 0 !important;

      a.active {
        color: var(--light-special);

        .nav-item {
          font-weight: 900 !important;

          &:hover {
            color: var(--light-special);
          }
        }
      }

      .nav-item {
        user-select: none;
        padding: 0.25rem 1rem;
        transition: 0.2s ease-in;
        text-transform: capitalize;

        font-family: "Orbitron";
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 500;

        &:hover {
          color: #bbb;
        }
      }

      .nav-icons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-left: 1px solid #fefefe;

        svg {
          cursor: pointer;
          color: #fefefe;
          width: 20px;
          height: 20px;
          transition: 0.1s ease-in;
        }

        svg:last-child {
          margin-left: 1rem;
        }

        .github:hover {
          svg {
            color: #c9510c;
          }
        }

        .linkedin:hover {
          svg {
            color: #00a0dc;
          }
        }
      }
    }

    .mobile-wrapper {
      .icon {
        display: none;
      }
    }
  }
}

/* MEDIA QUERIES */
@media screen and (max-width: 720px) {
  .mobile-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;

    .logo {
      font-size: 2rem;
    }

    .burger-menu {
      display: inline-block !important;
    }
  }

  .nav {
    flex-direction: column;

    .nav-menu {
      background-color: #000;
      width: 100%;
      flex-direction: column;
      z-index: 2;

      .nav-item {
        font-size: 1.25rem !important;
        padding: 1rem 0 !important;
      }
    }

    .nav-icons {
      border: none !important;
      padding: 0.5rem 0 !important;

      a {
        i {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem !important;
          margin: 0 !important;
          padding: 0 0.5rem;
        }
      }
    }
  }

  .mobile-active {
    // height: 100vh;

    .nav {
      // height: 100%;
      display: flex;
      // align-items: center;

      .nav-menu {
        // height: 100%;
        // width: 100%;
        // display: grid;
        // grid-template-rows: repeat(5, 1fr);

        a {
          display: grid;
          place-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .mobile-inactive {
    .nav-menu {
      display: none !important;
      visibility: none !important;
    }
  }
}
