.main {
  width: 100%;
  min-height: 600px;
  max-height: 800px;
  position: relative;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
  z-index: 1;

  .hero-section {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;

    .hero-title {
      text-align: center;
      font-size: 2.2rem;
      font-weight: 800;
      font-family: "Orbitron";

      background: -webkit-linear-gradient(
        var(--light-special),
        var(--normal-special)
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .hero-sub-title {
      margin-top: 1.5rem;
      color: var(--light-special);
    }
  }

  .hero-links {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    .hero-btn {
      cursor: pointer;
      background-color: #000;
      box-shadow: 7px 7px 14px 4px #181818;
      text-shadow: 7px 7px 14px 4px #181818;
      color: var(--light-special);
      color: #fefefe;
      padding: 1rem 1.75rem;
      border: none;
      border-radius: 40px;
      font-size: 1rem;
      outline: none;
      transition: all 0.1s ease;

      font-weight: 600;
      text-transform: uppercase;
      font-family: "Orbitron";
      font-size: 0.9rem;

      &:hover {
        color: var(--light-special);
        box-shadow: 0px 1px 2px 0px rgba(97, 219, 251, 0.7),
          1px 2px 4px 0px rgba(97, 219, 251, 0.7),
          2px 4px 8px 0px rgba(97, 219, 251, 0.7),
          2px 4px 16px 0px rgba(97, 219, 251, 0.7);

        transform: scale(1.1);
      }

      &:focus {
        outline: none;
      }
    }

    .main-icons {
      display: flex;
      gap: 0.5rem;
      .social-icon {
        svg {
          box-sizing: content-box;
          color: #fefefe;
          width: 25px !important;
          height: 25px !important;
          padding: 0.75rem;
          transition: all 0.15s ease-in;

          border-radius: 18px;
          border: 2px solid #000;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .github:hover {
        svg {
          color: #c9510c;
        }
      }

      .linkedin:hover {
        svg {
          color: #00a0dc;
        }
      }

      .github {
        &:hover {
          svg {
            border: 2px solid rgba(201, 81, 12, 0.15);
            box-shadow: 5px 5px 10px 3px #c9510c;
            text-shadow: 5px 5px 10px 3px #c9510c;

            color: #c9510c;
          }
        }
      }

      .linkedin {
        &:hover {
          svg {
            border: 2px solid rgba(0, 160, 220, 0.15);
            box-shadow: 5px 5px 10px 3px #00a0dc;
            text-shadow: 5px 5px 10px 3px #00a0dc;

            color: #00a0dc;
          }
        }
      }

      .fa-address-card:hover {
        color: #59c8f0;
      }
    }
  }

  .gif {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  .gif-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-gif {
    position: absolute;
    top: 75px;
    right: 50px;
  }

  .github-gif {
    position: absolute;
    left: 5rem;
    bottom: 0px;
  }
}

/* MEDIA QUERIES */
@media (max-width: 1420px) {
  .react-gif {
    display: none;
  }
}

@media (max-width: 985px) {
  .main {
    .hero-section {
      .hero-title {
        font-size: 1.75rem;
      }

      .hero-sub-title {
        font-size: 1.25rem;
      }
    }
  }
}

@media (max-width: 875px) {
  .main {
    flex-direction: column;

    .hero-section {
      padding: 0;
      margin-top: 7.5rem;

      .gif-wrapper {
        display: none;
        align-items: flex-start;
      }

      .react-gif {
        display: none;
      }
    }
  }
}

@media (max-width: 700px) {
  .main {
    .hero-section {
      .hero-title {
        font-size: 1.75rem;
      }

      .hero-sub-title {
        font-size: 1.4rem;
      }

      .hero-btn {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .main {
    .hero-section {
      .hero-text {
        font-size: 1.5rem;
      }

      .hero-sub-title {
        font-size: 1.2rem;
      }
    }
    // .github-gif {
    //   display: none;
    // }
  }
}

@media (max-width: 420px) {
  .hero-title {
    font-size: 1.2rem;
  }

  .hero-sub-title {
    font-size: 1rem;
  }

  .github-gif {
    display: none;
  }
}
