.contact-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .contact {
    margin-top: 6rem;
    width: 80%;
    padding: 1rem 0;

    .contact-content {
      margin-top: 2rem;
      width: 100%;
      display: flex;
    }

    .contact-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */

      .sub-title {
        color: #dcdcdc;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 1rem;
        font-family: "Orbitron";
      }

      .title {
        margin: 0;
        margin-top: 0.5rem;
        padding: 0;
        font-size: 2.5rem;
        font-family: "Orbitron";
        font-weight: 800;
        text-transform: uppercase;
        background: -webkit-linear-gradient(
          var(--light-special),
          var(--dark-special)
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

@media (max-width: 1060px) {
  .contact-wrapper {
    .contact {
      .contact-content {
        flex-direction: column;

        .contact-info {
          display: none;
          border: none;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .contact-wrapper {
    .contact {
      .contact-header {
        .sub-title {
          font-size: 0.9rem;
        }

        .title {
          font-size: 2.2rem;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .contact-wrapper {
    .contact {
      .contact-header {
        .sub-title {
          font-size: 0.8rem;
        }

        .title {
          font-size: 2rem;
        }
      }
    }
  }
}
