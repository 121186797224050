.about-skills {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .skills-title {
    margin: 3rem 0 1.25rem 0;

    background: -webkit-linear-gradient(
      var(--light-special),
      var(--dark-special)
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;

    text-align: center;

    font-size: 2.5rem;
    font-family: "Orbitron";
    text-transform: uppercase;
    font-weight: 900;
  }

  .images {
    display: flex;
    margin-top: 1rem;
    gap: 1.25rem;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 48px;
      height: 48px;
    }

    img:first-child {
      margin: 0;
    }
  }
}

@media screen and (max-width: 960px) {
  .about-skills {
    .skills-title {
      font-size: 2rem;
    }
  }
}
