.reset-filters {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(15, 15, 15, 0.75);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .info-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .main-info {
      font-family: "Orbitron";
      text-transform: uppercase;
      font-weight: 900;
      font-size: 1.25rem;
    }

    .sub-info {
      margin-top: 0.75rem;
      font-family: "Orbitron";
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.9rem;
      color: #ddd;
    }

    .btn-container {
      width: 100%;
      display: flex;
      justify-content: center;

      .reset-btn {
        width: 60%;
        cursor: pointer;
        box-sizing: border-box;
        margin-top: 1.5rem;
        padding: 1rem 2rem;

        background-color: #171717;
        color: var(--light-special);

        font-family: "Orbitron";
        text-transform: uppercase;
        font-weight: 900;

        outline: none;
        border: none;
        border-radius: 20px;
        transition: all 0.2s ease;

        &:hover {
          color: var(--light-special);
          box-shadow: 0px 1px 2px 0px rgba(97, 219, 251, 0.7),
            1px 2px 4px 0px rgba(97, 219, 251, 0.7),
            2px 4px 8px 0px rgba(97, 219, 251, 0.7),
            2px 4px 16px 0px rgba(97, 219, 251, 0.7);
        }
      }
    }
  }
}
