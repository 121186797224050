.fw-wrapper {
  width: 100vw;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #343434;

  .fw {
    width: 80%;
    display: flex;
    flex-direction: column;

    .fw-header {
      display: flex;
      align-items: center;

      .section-title {
        margin: 0;
        padding: 0;
        font-size: 3rem;
        text-transform: uppercase;
        font-family: "Orbitron";
        background: -webkit-linear-gradient(
          var(--light-special),
          var(--dark-special)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .fw-content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;
    }
  }
}

@media (max-width: 520px) {
  .fw-wrapper {
    .fw {
      .fw-header {
        .section-title {
          font-size: 2.4rem;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .fw-wrapper {
    .fw {
      .fw-header {
        .section-title {
          font-size: 2rem;
        }
      }
    }
  }
}
