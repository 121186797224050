.introduction {
  display: flex;
  font-size: 1.1rem;

  .info-container {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .title {
      width: 100%;
      text-align: center;
      font-family: "Orbitron";
      font-size: 2.5rem;
      text-transform: uppercase;
      font-weight: 900;
    }

    .sub-title {
      width: 100%;
      text-align: center;
      margin-top: 0.5rem;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.8rem;
    }

    .text {
      width: 100%;
      margin-top: 3rem;
      display: flex;
      flex-direction: column;

      font-weight: 600;
      font-size: 1.1rem;

      .important {
        margin-bottom: 1.5rem;
      }

      .header-container {
        margin-bottom: 2.5rem;
        position: relative;

        .header {
          position: absolute;
          font-size: 1.5rem;
          font-weight: 400;
          letter-spacing: 3px;
          font-family: "Orbitron";
        }

        .header-shadow {
          font-weight: 700;
          left: 0.15rem;
          top: -0.15rem;
          color: var(--dark-special);
          opacity: 0.6;
        }

        .header-text {
          font-weight: 700;
          color: var(--light-special);
          opacity: 0.85;
        }
      }

      .less-important {
        margin: 0.5rem 0;
        font-size: 1rem;
        font-family: "Orbitron";
        text-transform: uppercase;
        font-size: 0.9rem;

        a {
          color: #bbb;
          font-family: "Orbitron";
          text-transform: uppercase;
          font-size: 0.9rem;

          &:hover {
            color: var(--light-special);
          }
        }
      }
      a {
        text-decoration: none;

        .resume-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 1.25rem;
          margin-top: 1rem;
          padding: 0.5rem 1rem;
          border: none;
          outline: none;
          border-radius: 15px;
          color: #bbb;
          background-color: #181818;
          transition: all 0.15s ease;
          font-family: "Orbitron";
          font-weight: 900;
          text-transform: uppercase;

          &::before {
            position: absolute;
            top: -200%;
            left: -250%;
            z-index: 999;
            display: block;
            content: "";
            width: 200%;
            height: 400%;
            background: -webkit-linear-gradient(
              left,
              rgba(0, 174, 255, 0.25) 25%,
              rgba(1, 204, 255, 0.5) 100%
            );
            background: linear-gradient(
              to right,
              rgba(0, 174, 255, 0.25) 25%,
              rgba(1, 204, 255, 0.5) 100%
            );
            -webkit-transform: skewX(-25deg);
            transform: skewX(-25deg);
          }

          &:hover {
            &::before {
              -webkit-animation: circle 0.25s;
              animation: circle 0.5s;
            }
          }

          &:hover {
            color: var(--light-special);
            box-shadow: 0px 1px 2px 0px rgba(97, 219, 251, 0.7),
              1px 2px 4px 0px rgba(97, 219, 251, 0.7),
              2px 4px 8px 0px rgba(97, 219, 251, 0.7),
              2px 4px 16px 0px rgba(97, 219, 251, 0.7);
          }

          svg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.75rem;
            height: 22px;
            width: 22px;
          }
        }
      }

      .resume-btn:hover {
        color: var(--light-special);
      }
    }
  }

  .profile-pic {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .figure {
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: 25px;

      transform: scale(1.65) rotate(-12.5deg) translateX(75px);

      img {
        border-radius: 25px;
        width: 300px;

        // transform: scale(1.65) rotate(-12.5deg) translateX(75px);
        filter: sepia(10%) grayscale(50%) opacity(0.55);
        transition: filter 0.15s ease-in-out;

        &:hover {
          filter: sepia(100%) grayscale(0%) opacity(1);
        }
      }

      &:hover {
        .fig {
          figcaption {
            display: flex;
            opacity: 0.85;
          }
        }
      }

      .fig {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        position: absolute;
        pointer-events: none;

        transition: all 0.075s ease-in;

        left: 1rem;
        bottom: 1.5rem;

        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */

        figcaption {
          display: none;

          background: -webkit-linear-gradient(
            left,
            rgb(255, 254, 178),
            rgba(204, 156, 0, 0.911)
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;

          transition: all 0.075s ease-in;

          font-family: "Orbitron";
          font-weight: 900;
          text-transform: uppercase;
          // font-size: 1.25rem;
          opacity: 0;

          &:last-child {
            margin-top: 1rem;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1060px) {
  .introduction {
    .info-container {
      width: 100%;
    }

    .profile-pic {
      display: none;
    }
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 400%;
    height: 600%;
    opacity: 0;
  }
}

@media screen and (max-width: 960px) {
}
