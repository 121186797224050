.projects-wrapper {
  width: 100vw;
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .projects {
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: column;
  }
}
