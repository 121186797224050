.contact-form {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .input-container {
    min-width: 300px;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    label {
      text-transform: uppercase;
      margin-bottom: 0.25rem;

      font-family: "Orbitron";
      font-weight: 700;
      color: #aaa;

      font-size: 1.1rem;

      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
    }

    input,
    textarea {
      margin-top: 0.5rem;
      border: none;
      outline: none;
      padding: 0.5rem 0.5rem;
      border-radius: 12px;
      background-color: #181818;
      color: #dcdcdc;

      font-size: 1rem;
      font-family: "Orbitron";
      color: var(--normal-special);
      font-weight: 500;

      border-bottom: 3px solid #333;
      margin-bottom: -3px;

      &:hover,
      &:focus {
        background-color: #151515;
      }
    }

    input:focus {
      border-bottom: 3px solid var(--normal-special);
      margin-bottom: -3px;
    }

    input:not(:empty) {
      border-bottom: 3px solid var(--normal-special);
      margin-bottom: -3px;
    }

    textarea:focus {
      outline: 1px solid var(--normal-special);
    }

    textarea {
      height: 200px;
      resize: vertical;
    }
  }

  .input-container:last-child {
    margin: 0;

    .input {
      padding-bottom: 2rem;
      display: flex;
    }
  }

  .submit-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .submit-btn {
      cursor: pointer;
      width: 300px;
      padding: 0.75rem 1rem;
      border: none;
      outline: none;
      border-radius: 12px;

      background-color: #181818;
      color: #bbb;

      font-weight: 900;
      text-transform: uppercase;
      font-family: "Orbitron";
      font-size: 1.35rem;

      transition: all 0.2s ease;

      &:hover {
        color: var(--light-special);
        box-shadow: 0px 1px 2px 0px rgba(97, 219, 251, 0.7),
          1px 2px 4px 0px rgba(97, 219, 251, 0.7),
          2px 4px 8px 0px rgba(97, 219, 251, 0.7),
          2px 4px 16px 0px rgba(97, 219, 251, 0.7);
      }
    }
  }
}

@media (max-height: 760px) {
  .contact-form {
    .input-container {
      textarea {
        height: 120px;
      }
    }
  }
}
