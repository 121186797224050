.project-menu {
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  .section-menu {
    display: flex;
    flex-direction: column;
    align-items: center;

    .menu-title {
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Orbitron";
    }

    .list {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .menu-item {
        cursor: pointer;
        list-style-type: none;

        font-family: "Orbitron";
        font-weight: 500;

        &:hover {
          color: var(--light-special);
        }
      }

      .active {
        color: var(--light-special);
      }

      .inactive {
        color: #aaa;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .section-menu {
    &:nth-child(2) {
      margin-top: 1rem;
    }
  }
  .list {
    flex-wrap: wrap;
    justify-content: center;

    .menu-item {
      font-size: 0.9rem;
    }
  }
}
