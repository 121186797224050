.about-me-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 2rem;

  .about-me-content {
    width: 80%;
  }

  .special-text {
    font-weight: 700;
    font-family: "Quicksand";
    background: -webkit-linear-gradient(
      var(--light-special),
      var(--dark-special)
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
