.alert-box {
  width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  background-color: #151515;

  box-shadow: 5px 5px 15px 5px #252525;

  position: absolute;
  z-index: 555;
  top: 5rem;

  .alert-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    gap: 1rem;

    span {
      font-size: 2rem;
      letter-spacing: 2px;
      font-weight: 900;
      font-family: "Orbitron";
    }

    svg {
      cursor: pointer;
      width: 25px;
      height: 25px;
      margin-left: 1rem;
      transition: all 0.1s ease;
      color: #aaa;

      &:hover {
        color: red;
      }
    }

    .alert-text {
      color: var(--light-special);
    }

    .success {
      color: limegreen;
    }
    .info {
      color: yellow;
    }
    .fail {
      color: red;
    }
  }

  .error-messages {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    .error-msg {
      font-family: "Quicksand";
      color: #dcdcdc;
      font-size: 1.2rem;
    }
  }
}

.success {
  .error-messages {
    display: none;
  }
}
